import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
const messages = {
    'en': {
        terms: {
            title: "Terms of use",
            generalText: `CONNECTMEAE PORTAL offers many services to individuals to help connect them to the job market.

By requesting CONNECTMEAE PORTAL services and using its tools and resources, you agree and acknowledge that you have read,
understood and fully agree to be bound by our Terms of Service and
Privacy Policy. These Terms and Conditions govern the use of the
CONNECTMEAE PORTAL Services, Please read the following lines of agreement very
carefully.

If you do not agree to any of the terms mentioned, you may choose not
to proceed with your service request, in case you have any questions
contact us for further clarification.

- Information validity:

   The user guarantees that all the information mentioned in his account or in his CV is real 100% , and he shall subsequently bear all the responsibility incurred in any case opposing it.

   So make sure that it is completely correct and reliable before applying and uploading your CV.
   
   If you have any questions, our team is ready to help.

- User age:

   The user must be 18 years old or over.

- Return Policy:

   When the user makes the payment, the platform will automatically send the CV to all the companies listed in the purchased package, and accordingly the user is unable to recover the amount he paid in any way.

- Cancellation Policy:

   The transmission process is done directly after the user has paid the value of the desired package, and therefore it is not possible to cancel or stop any process after payment and start providing the required service.

- Send CVs:

   The platform has the right to send or stop the resumes it deems appropriate, with reference to the user and informing him of the choice made, whether to accept or reject.

- Price list and offers:

   The CONNECTMEAE PORTAL price list is for a specific period that is mentioned when the offer is submitted and is considered void after the expiry of this period.
   
   The number of companies included in the offer will be based on the applicant’s experience and career.

- CONNECTMEAE PORTAL Responsibility:

   The CONNECTMEAE PORTAL service is mainly based on connecting skilled people and job seekers with companies and institutions by sending the CV of the service applicant to a specified number of companies based on the package that he will subscribe to.
   
   CONNECTMEAE PORTAL is not responsible for the success or failure of the recruitment process, directly or indirectly, its work is limited to sending a CV by e-mail, and the companies will later take over the recruitment process and communicate with users to complete the recruitment process if it is successful.
   
   Therefore, our full commitment will be in the pre-employment phase, which will end the communication with the number of companies agreed upon.

   GOVERNING LAW

These terms shall be governed by and defined following the laws of the United Arab Emirates.CONNECTMEAE PORTAL and yourself irrevocably consent that the courts of the United Arab Emirates shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
`,
            buttonText: "Got it"
        },
        policy: {
            title: "Privacy and policy",
            generalText: `Your data in CONNECTMEAE PORTAL is treated with great care and we are committed to securing it and maintaining its safety and privacy.

What information will we collect from the user:
- Full name
- E-mail
- Telephone number
- Age
- Education
- Country and city of residence
- Employment
- Previous experience
- Recommendation letters
- Any information included in the CV
- Cookies and similar technologies:

Cookies are small text files that we send to your device (computer, device tablet and mobile) when you visit our website for the first time.

When you visit the site again, the server retrieves the cookie to help you work more efficiently; Because it remembers things like your login, language, font size, and other display preferences so you don't have to enter them every time.

(Greater provision by the technical team about the information to be collected from cookies)

Responsible for collecting and maintaining information:

The Collecting department is directly responsible for the process of collecting information and ensuring its preservation and confidentiality.

Who are the authorities authorized to access personal information:

The exclusive access to your information is entrusted only to CONNECTMEAE PORTAL and all the companies to which we will send the CV, we will not profit or sell this information to any third party without the consent of the user.

Your information will be accessed by government officials where there may be legal reporting requirements, local labor authorities, courts, tribunals, regulators, law enforcement agencies or private litigants.

We use the collected data to:

. We carry out your requests.
. We send you an order confirmation.
. We assess your needs to determine the appropriate services.
. We send the requested service data to you.
. We send you service updates.
. We send you reminders when you use the CONNECTMEAE PORTAL platform.

If you do not wish to receive these messages, you can control your choice either through your device or through the CONNECTMEAE PORTAL platform settings depending on your device type.

. We send you marketing ads
. We are developing the CONNECTMEAE PORTAL platform
. We do research and analysis
. We answer your questions and problems
. We display the content/data based on your interests

You have the right to request access to your personal data, as well as the right to erase your personal data from CONNECTMEAE PORTAL servers, if you do not want us to keep your information, please inform us via email. Our payment system is an integrated system associated with a third party payment service provider, through which your bill data can be collected directly from you for direct purposes, the process of paying your bill by the payment service provider.

Protection:

Protecting your personal data is important to us, and we follow global standards in protecting the data you provide to us.

When we provide you (or when you choose) a secret code that allows you to access parts of our Sites, you are responsible for keeping that secret code, and we ask that you not share it with anyone else.

The privacy policy is constantly changing, and users should review it periodically, and all users will be notified of any upcoming changes.

Six things you can do now to improve your CV:

When preparing the CV, the main objective remains: to create a positive impact on the reader and motivate him to communicate and offer job opportunities.

Through exposure to thousands of CVs, we found that there are some techniques that can be used that help develop the CV and make it more effective, impactful and able to stand out among the many CVs submitted for the same job opportunities.

In this article, we will give you 6 things that you can modify or add to improve your resume and give it a professional look.

Personal statement :

A personal statement is one of the most important defining elements of your personality and the first keys to knowing your future directions and past experiences, so writing it correctly will reflect positively on the recruitment process.

Keep it short, focus on keywords, mention your strengths, and if you need help, ask a colleague or friend about your strengths.

Mention accomplishments, not routines :

Use clear, strong language that focuses on your accomplishments in your previous work, not just the regular jobs and issues that are repeated in most resumes, so describe the exceptional accomplishments you have achieved over the past years.

And remember that the achievement is not always linked to large numbers or the publication of newspaper results, it is possible that the development of a new policy in the company led to higher results than the previous one.

Focus on keywords :

Each field has its own keywords, for example in the field of marketing you will see words such as (campaigns, content marketing, product development, return on investment, user journey) and many other terms that fall within the core of the work, so the more accurate you are in these terms The more professional your CV becomes and expresses a deep understanding of the field.

Make it targeted and customized :

We all know that mentioning all the different works that a person has done in the past is attractive to companies because it indicates that the person has expertise in many fields, but unfortunately this is not what companies are looking for most of the time and it may indicate that the person is not a specialist or expert in A specific area and can make an important difference within it.

Therefore, we recommend focusing on a specific field or specialty that is included in the CV in ascending order that shows the process of development formed year after year, then your chances will be higher in positions you have a long balance and focused experience to work in.

Use numbers and statistics:

Metrics vary naturally between humans, so the concept of “big” for example may differ between each individual, and this applies to previous achievements, what you may see as important work others may see as normal, so include your results with numbers and statistics and stay away from narrative description and broad terms.

If you were in charge of the recruitment department previously, do not say “I hired a large number of workers” and replace it with “ I contributed to interviewing and hiring 240 workers in two years " .

List your volunteer work :

Volunteer work is very important for the person’s skills through it, so put it in a special section in which you explain your experience in volunteer work in the same way that you explain your experience in the field of work, and focus on the skills you acquired from these works, which may be soft skills such as communication Organizing and managing teams.

GOVERNING LAW

These terms shall be governed by and defined following the laws of the United Arab Emirates. CONNECTMEAE PORTAL and yourself irrevocably consent that the courts of the United Arab Emirates shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.`

        },
        checkOut: "Checkout",
        getStart:"Connect Now!",
        CardholderName: "Name on card",
        Number: "Card number",
        CCV: "CCV/CVC",
        ExpiryYear: "Expiry year",
        ExpiryMonth: "Expiry month",
        pay: "Pay",
        backHome: "Home",
        planApply: "Apply"
    },
    'ar': {
        terms: {
            title: "شروط الاستخدام",
            generalText: `تقدم CONNECTMEAE PORTAL العديد من الخدمات للأفراد للمساعدة في ربطهم بسوق العمل. من خلال طلب خدمات CONNECTMEAE PORTAL، واستخدام أدواتها والموارد الخاصة بها، فأنت بذلك توافق وتقر على أنك قرأت وفهمت وتوافق تماماً على الالتزام بشروط الخدمة وسياسة الخصوصية الخاصة بنا. تحكم هذه الشروط والأحكام استخدام خدمات CONNECTMEAE PORTAL،، لذا يرجى قراءة خطوط الاتفاقية التالية بعناية شديدة، وإذا كنت لا توافق على أي من الشروط المذكورة، فيمكنك اختيار عدم متابعة طلب الخدمة الخاص بك، في حال كان لديك أي أسئلة اتصل بنا للحصول على مزيد من التوضيح.

-صحة المعلومات المقدمة:
يضمن المستخدم أن كافة المعلومات المذكورة في حسابه أو في سيرته الذاتية حقيقية 100% ويتحمل لاحقاً كافة المسؤولية المترتبة بحال مخالفة ذلك لذا تأكد من صحتها وموثوقيتها بشكل كامل قبل تقديم الطلب ورفع السيرة الذاتية. وبحال لديك أية سؤال ففريقنا جاهز لتقديم المساعدة.

-عمر المستخدم:
أن يكون عمر المستخدم 18 عاماً أو أكثر.

-سياسة الإرجاع:
عند قيام المستخدم بعملية الدفع فإن المنصة ستقوم تلقائياً بإرسال السيرة الذاتية إلى كافة الشركات الوارد عددها ضمن الباقة التي تم شرؤها، وعليه فإن المستخدم  غير قادر على استرجاع المبلغ الذي قام بدفعه بأية شكل من الأشكال.

-سياسة الإلغاء:
تتم عملية الإرسال بشكل مباشر بعد قيام المستخدم بدفع قيمة الباقة المرادة، وعليه فلا يمكن إلغاء أو إيقاف أية عملية بعد الدفع والمباشرة بتقديم الخدمة المطلوبة.

-إرسال السير الذاتية:
يحق للمنصة إرسال أو إيقاف السير الذاتية التي تراها مناسبة مع الرجوع للمستخدم وإعالمه بالخيار الذي تم اتخاذه سواء بالقبول أو الرفض.

-لائحة الأسعار و العروض:
تعتبر لائحة الأسعار في CONNECTMEAE PORTAL ذات مدة محددة يتم ذكرها عند تقديم العرض وتعتبر لاغية بعد انتهاء هذه المدة. كما أن أعداد الشركات المضمنة بالعرض ستكون بناءً على خبرة المتقدم ومسيرته المهنية

-مسؤولية CONNECTMEAE PORTAL:
تقوم خدمة CONNECTMEAE PORTAL بشكل أساسي على ربط أصحاب المهارات والباحثين عن عمل بالشركات والمؤسسات عن طريق إرسال السيرة الذاتية لطالب الخدمة إلى عدد محدد من الشركات بناءً على الباقة التي سيقوم بالاشتراك بها.
وتعتبر شركة CONNECTMEAE PORTAL غير مسؤولة عن نجاح أو فشل عملية التوظيف بشكل مباشر أو غير مباشر، ويقتصر عملها على إرسال السيرة الذاتية عن طريق الإيميل وتتولى الشركات لاحقاً عملية التوظيف بحال نجاحها.
لذا فإن التزامها الكامل سيكون في مرحلة ما قبل التوظيف و هي التواصل مع عدد الشركات المتفق عليه.

القانون الحاكم.

تخضع هذه الشروط لقوانين دولة الإمارات العربية المتحدة وتُعرّف وفقًا لها ، وتوافق بوابة CONNECTMEAE PORTAL وأنت نفسك بشكل نهائي على أن يكون لمحاكم دولة الإمارات العربية المتحدة الاختصاص القضائي الحصري لحل أي نزاع قد ينشأ فيما يتعلق بهذه الشروط.
`,
            buttonText: "حسناً"
        },
        policy: {
            title: "بيان الخصوصية",
            generalText: `تعامل بياناتك في CONNECTMEAE PORTAL بحرص عالٍ ونلتزم بتأمينها والحفاظ على سلامتها وخصوصيتها.
            

ما هي المعلومات التي سنقوم بجمعها من المستخدم:
-الاسم الكامل
-البريد الالكتروني
-رقم الهاتف
-العمر
-التعليم
-بلد ومدينة الإقامة
-مجال العمل
-الخبرات السابقة
-رسائل التوصية
-أية معلومات واردة ضمن السيرة الذاتية
-ملفات تعريف الارتباط والتقنيات المشابهة: ملفات تعريف الارتباط هي ملفات نصية صغيرة نرسلها إلى جهازك (الكمبيوتر،والجهاز اللوحي،والجوال)، عند زيارتك لموقعنا الإلكتروني لأول مرة. وعندما تزور الموقع مرة أخرى، يسترد الخادم ملف تعريف الارتباط لمساعدتك على العمل بكفاءة أكبر؛ لأنه يتذكر أشياء مثل تسجيل الدخول واللغة و حجم الخط وتفصيلات العرض الأخرى بحيث لا تضطر إلى إدخالها في كل مرة. (تزويد أكبر من قبل الفريق التقني حول المعلومات التي سيتم جمعها من الكوكيز).

المسؤول عن عملية جمع المعلومات والحفاظ عليها:
يعتبر قسم تحصيل المعلومات هو المسؤول المباشر عن عملية جمع المعلومات والتأكد من الحفاظ عليها وعلى سريتها.

من هي الجهات المخولة بالإطلاع على المعلومات الشخصية:
تكون حصرية الوصول إلى معلوماتك منوطة فقط بشركة CONNECTMEAE PORTAL وكافة الشركات التي سنقوم بإرسال السيرة الذاتية إليها، لن نقوم بالاستفادة أو بيع هذه المعلومات إلى أية جهة ثالثة دون موافقة من قبل المستخدم. سيتم الوصول إلى معلوماتك من قبل المسؤولين الحكوميين حيث قد تكون هناك متطلبات إبلاغ قانونية، أو سلطات العمل المحلية، أو المحاكم، أو الهيئات القضائية، أو الهيئات التنظيمية، أو وكالات تنفيذ القانون أو المتقاضين الخاصين.

نقوم باستخدام البيانات المجمعة لكي:
-نقوم بتنفيذ طلباتك.
-نقوم بإرسال تأكيد الطلب إليك.
-نقوم بتقييم احتياجاتك لتحديد الخدمات المناسبة.
-نقوم بإرسال بيانات الخدمة المطلوبة إليك.
-نقوم بإرسال تحديثات الخدمة إليك.
-نقوم بإرسال رسائل تذكيرية إليك عندما تقوم باستخدام منصة CONNECTMEAE PORTAL. وإذا كنت لا ترغب في تلقي هذه الرسائل، يمكنك التحكم في اختيارك سواء من خلال جهازك أو من خلال إعدادات منصة CONNECTMEAE PORTAL حسب نوع جهازك. كما يمكنك الاتصال بنا على البريد الإلكتروني.
-نقوم بإرسال إعلانات تسويقية إليك.
-نقوم بتطوير منصة CONNECTMEAE PORTAL.
-نقوم بإجراء بحث و تحليل.
-نقوم بالإجابة على أسئلتكم ومشاكلكم.
-نقوم بعرض المحتوى/البيانات بناء على ما تقضيه مصالحك.

لديك الحق في طلب الوصول إلى بياناتك الشخصية، وكذلك الحق في مسح بياناتك الشخصية من خوادم CONNECTMEAE PORTAL، إذا كنت لا تريد منا الاحتفاظ بمعلوماتك، يرجى إبالغنا عبر الإيميل: (ذكر الإيميل)


إن نظام الدفع الذي نتبعه هو نظام متكامل مرتبط مع مزود خدمات الدفع من الغير والذي يمكن من خلاله جمع بيانات فاتورتك منك بشكل مباشر لأغراض مباشرة عملية سداد فاتورتك من قبل مزود خدمات الدفع.


الحماية:

إن حماية بياناتك الشخصية أمر هام بالنسبة لنا، ونحن نتبع معايير عالمية في حماية البيانات التي تقوم بتقديمها لنا.


عندما نقدم لك(أو عندما تختار) رمزا سريا يسمح لك بالدخول إلى أجزاء من مواقعنا، فإنك تتحمل مسؤولية الحفاظ على سرية ذلك الرمز السري، كما نطلب منك عدم مشاركته مع أي شخص آخر.


 تعتبر سياسة الخصوصية ذات تغيير مستمر وعلى المستخدمين الاطلاع عليها بشكل دوري، كما سيتم إعالم جميع المستخدمين بحال وجود أية تغييرات قادمة.

 القانون الحاكم.

تخضع هذه الشروط لقوانين دولة الإمارات العربية المتحدة وتُعرّف وفقًا لها ، وتوافق بوابة CONNECTMEAE PORTAL وأنت نفسك بشكل نهائي على أن يكون لمحاكم دولة الإمارات العربية المتحدة الاختصاص القضائي الحصري لحل أي نزاع قد ينشأ فيما يتعلق بهذه الشروط.
`
        },
        checkOut: "الدفع",
        CardholderName: "إسم صاحب البطاقة",
        Number: "رقم البطاقة",
        getStart:"تواصل الأن",

        CCV: "CCV/CVC",
        ExpiryYear: "سنة الانتهاء",
        ExpiryMonth: "شهر الانتهاء",
        pay: "ادفع",
        backHome: "الأساسية",
        planApply: "اختيار"
    }
};


export const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
});
