<template>
  <div class="footer--wrapper" :class="{ 'footer--wrapperAr': $vuetify.rtl }">
    <h1 class="headerSection" v-if="$vuetify.rtl">
      ستشرق في مهنتك مع
      <span class="footer--C">C</span>onnect me
    </h1>
    <h1 class="headerSection" v-else>
      Connect with
      <span class="footer--C">C</span>onnect me
    </h1>
    <v-container>
      <div class="footer-sections">
        <div class="footer--col m">
          <h3>{{ watchLang.findUs }}</h3>

          <p v-if="$vuetify.rtl">
            <i class="fas con fa-map-marker-alt"></i>
            <span>
              الإمارات العربية المتحدة دبي،<br />
              المرقبات ، مركز وربة للأعمال - مكتب M26<br />
              رقم المكتب: 009714-2228242
            </span>
          </p>
          <p v-else>
            <i class="fas con fa-map-marker-alt"></i>
            <span>
              Office M26, Warba Business Center
              <br />
              Al Muraqqabat, Dubai – UAE
              <br />
            </span>
          </p>
          <p><i class="fas con fa-mobile-alt"></i>+971 4 222 82 42</p>
          <p>
            <i
              class="fa con fa-envelope footer--socialIcon"
              aria-hidden="true"
            ></i>
            <a class="email" href="mailto:customerservice@connectmeae.com">
              customerservice@connectmeae.com</a
            >
          </p>
        </div>
        <div class="footer--col">
          <h3>{{ watchLang.information }}</h3>
          <p class="footer--informationItem" @click="$router.push('/AboutUs')">
            {{ watchLang.information1 }}
          </p>
          <app-privacy-and-policy></app-privacy-and-policy>
          <app-terms-and-conditions></app-terms-and-conditions>
          <p
            class="footer--informationItem"
            @click="$router.push('/StartYourCarrer')"
          >
            {{ watchLang.information5 }}
          </p>
        </div>

        <div class="footer--col social">
          <img class="logo" src="@/assets/Connectme.png" alt="" />
          <div class="footer--socialsWrapper">
            <div class="footer--socialIconWrapper1">
              <i
                @click="goToLink('https://www.facebook.com/ConnectmeaePortal/')"
                ><img src="@/assets/facebook.svg"
              /></i>

              <!-- <i
                class="fab fa-facebook-f footer--socialIcon"
                @click="goToLink('https://www.facebook.com/ConnectmeaePortal/')"
              ></i>  -->
            </div>
            <div
              class="footer--socialIconWrapper2"
              :class="{ 'footer--socialIconWrapper2Ar': $vuetify.rtl }"
            >
              <i @click="goToLink('https://www.instagram.com/connectmeae/')"
                ><img src="@/assets/instagram.svg"
              /></i>
            </div>
            <!-- <div
              class="footer--socialIconWrapper3"
              :class="{ 'footer--socialIconWrapper3Ar': $vuetify.rtl }"
            >
               <i  @click="goToLink('https://www.linkedin.com/in/connectme-uae-06b4a8212/')"><img src="@/assets/linkedin.svg"/></i> 
            </div> -->
            <div
              class="footer--socialIconWrapper3"
              :class="{ 'footer--socialIconWrapper3Ar': $vuetify.rtl }"
            >
              <i>
                <a href="https://wa.me/971524939300" target="_blank"
                  ><img src="@/assets/Whatsappc.svg" alt=""
                /></a>
              </i>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import termsAndConditions from "../components/termsAndConditions";
import privacyAndPolicy from "../components/privacyAndPolicy";
export default {
  components: {
    appTermsAndConditions: termsAndConditions,
    appPrivacyAndPolicy: privacyAndPolicy,
  },
  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    watchLang() {
      let texts = {
        aboutUs: "About us",
        aboutUsParagpraph:
          "Based locally in the UAE, we specialize in linking companies with talented individuals in the most time-efficient and affordable way. We provide decent and suitable employment opportunities for all professions.",
        information: "Information",
        information1: "About us",
        information4: "Contact us",
        information5: "Get Started",
        findUs: "Contact us",
        footerButton: "Get Started",
      };
      if (this.$vuetify.rtl) {
        texts.aboutUs = "من نحن";
        texts.aboutUsParagpraph =
          "شركة مختصة بربط الشركات بالأشخاص الباحثين عن عمل بأسرع الطرق وأقل التكاليف، نقوم برفد الشركات بأفضل المواهب وتأمين فرص عمل لائقة ومناسبة لجميع المهن والوظائف والمناصب.";
        texts.information = "معلومات";
        texts.information1 = "من نحن";
        texts.information4 = "تواصل معنا";
        texts.information5 = "ابدأ رحلتك";
        texts.findUs = "تجدنا في";
        texts.footerButton = "ابدأ";
      }
      return texts;
    },
  },
};
</script>

<style>
.footer--wrapper .email {
  color: #fff !important;
}
.footer--wrapper .footer-sections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
a:hover {
  text-decoration: none;
}
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer--wrapper {
  padding: 30px 0;
  position: relative;
  background: #589ca7;
  z-index: 1;
  width: 100%;
}
.footer--wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  background-image: url(/img/Connectme.647f3bc5.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}
.footer--wrapperAr {
  text-align: right;
}
.footer--wrapperAr p {
  font-size: 1.1rem;
}
.footer--wrapper h1 {
}
.footer--wrapper .con {
  padding: 0 10px;
  font-size: 27px;
  color: #f39f3c;
}
.m p {
  margin-left: -10px;
  margin-right: -10px;
}
.footer--informationItem {
  cursor: pointer;
  transition: 0.5s;
}
.footer--informationItem:hover {
  color: #f39f3c;
}
.footer--socialsWrapper {
  display: flex;
  /* justify-content: center; */
}
.footer--socialsWrapper img {
  width: 32px;
  border-radius: 50%;
}
.footer--socialIconWrapper1,
.footer--socialIconWrapper2,
.footer--socialIconWrapper3 {
  width: 40px;
  height: 40px;
  /* border: 1px solid white; */
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer--socialIconWrapper2,
.footer--socialIconWrapper3 {
  margin-left: 20px;
}
.footer--socialIconWrapper2Ar,
.footer--socialIconWrapper3Ar {
  margin-left: 0;
  margin-right: 20px;
}
.footer--socialIconWrapper1:hover .footer--socialIcon,
.footer--socialIconWrapper2:hover .footer--socialIcon,
.footer--socialIconWrapper3:hover .footer--socialIcon {
  transform: scale(1.2);
  color: #f39f3c;
  transition: 0.5s;
}
.footer--socialIcon {
  font-size: 1.2rem;
  transition: 0.5s;
}
.footer--C {
  color: #f39f3c;
}
.footer--col p {
  display: flex;
}
.footer--col .logo {
  width: 132px;
}
</style>
