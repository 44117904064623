<template>
  <div>
    <div style="position: relative">
      <div class="topWrapp">
        <div class="stepperWrapper">
          <app-stepper
            :elementNumber="1"
            :switchColor="elements.firstElComplete"
            :completeLine="true"
          ></app-stepper>
          <app-stepper
            :elementNumber="2"
            :switchColor="elements.secondElComplete"
            :completeLine="true"
          ></app-stepper>
          <app-stepper
            :elementNumber="3"
            :switchColor="elements.thirdElComplete"
          ></app-stepper>
        </div>
        <h2>{{ watchLang }}</h2>
      </div>
      <div class="componentWrapper">
        <transition name="slide">
          <component :is="checkCheck"></component>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import startCareerComplete from "../components/startCareerComplete";
import startCareerForm from "../components/startCareerForm";
import startCareerPlans from "../components/startCareerPlans";
import stepper from "../components/stepper";
export default {
  data() {
    return {
      selectedComponent: "app-start-career-form",
      stepMessage: "Start Your Career",
      elements: {
        firstElComplete: false,
        secondElComplete: false,
        thirdElComplete: false,
      },
    };
  },
  methods: {},

  computed: {
    checkCheck() {
      let stageCounter = this.$store.getters.getStageCounter;
      if (stageCounter == 1) {
        this.elements.firstElComplete = false;
        this.elements.secondElComplete = false;
        this.elements.thirdElComplete = false;
        return (this.selectedComponent = "app-start-career-form");
      }
      if (stageCounter == 2) {
        this.stepMessage = "Choose Your Plan";
        this.elements.firstElComplete = true;
        return (this.selectedComponent = "app-start-career-plans");
      }
      if (stageCounter == 3) {
        this.stepMessage = "Your CV Sent Successfully";
        this.elements.firstElComplete = true;
        this.elements.secondElComplete = true;
        this.elements.thirdElComplete = true;
        return (this.selectedComponent = "app-start-career-complete");
      }
    },
    watchLang() {
      let stepMessage = "";

      if (this.$vuetify.rtl == true) {
        if (this.stepMessage == "Start Your Career") {
          stepMessage = "ابدأ حياتك المهنية";
        } else if (this.stepMessage == "Choose Your Plan") {
          stepMessage = "اختر خطتك";
        } else {
          stepMessage = "تم رفع سيرتك الذاتية بنجاح";
        }
        return stepMessage;
      } else {
        return this.stepMessage;
      }
    },
  },
  components: {
    appStepper: stepper,
    appStartCareerForm: startCareerForm,
    appStartCareerPlans: startCareerPlans,
    appStartCareerComplete: startCareerComplete,
  },
};
</script>

<style>
.topWrapp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #217b8a;
  width: 100%;
padding: 19px 0;
  border-bottom-right-radius: 20%;
}
.topWrapp h2 {
  margin-top: 2%;
}
.stepperWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentWrapper {
  box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
}

.slide-enter-active {
  transition: 1s;
}
.slide-leave-active {
  transition: 1s;
  position: absolute;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>