<template>
  <div class="home-page">
    <div class="homePage__section1Wrapper">
      <v-container>
        <v-row>
          <v-col sm="7">
            <div class="homePage__section1TextWrapper">
              <h1><span class="homePage__section1Span">C</span>ONNECT ME</h1>
              <h5>{{ watchLang.homeText1 }}</h5>
              <h5>{{ watchLang.homeText2 }}</h5>
              <h5>{{ watchLang.homeText3 }}</h5>
              <v-btn
                rounded
                x-large
                color="#F39F3C"
                class="homePage__button animate__animated animate__swing"
                :class="{ homeButtonRTL: $vuetify.rtl }"
                @click="$router.push('/StartYourCarrer')"
                >{{ watchLang.homeButtonText }}</v-btn
              >
            </div>
          </v-col>
          <v-col sm="5">
            <div class="homePage__section1LottieWrapper">
              <lottie
                :options="defaultOptions"
                :height="300"
                :width="300"
                v-on:animCreated="handleAnimation"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="homePage__section2Wrapper">
      <h1 class="headerSection">{{ watchLang.howWeWorkHead }}</h1>
      <p class="section2--generalText headerParaghraph">
        {{ watchLang.howWeWorkGeneralText }}
      </p>
      <v-container>
        <v-row>
          <v-col
            md="4"
            cols="12"
            v-for="(data, index) in cardData"
            :key="index"
          >
            <a class="scroll" :href="index == 1 && '/#plans'">
              <div class="homePage__howWeWorkCard">
                <h2>{{ data.headTitle }}</h2>
                <i class="cardIcon" :class="data.icon" aria-hidden="true"></i>
                <h3>{{ data.title }}</h3>
                <h6>{{ data.description }}</h6>

                <!-- <p>
                {{ data.description }}
              </p> -->
              </div>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div>
      <app-values></app-values>
      <app-start-career-plans />
    </div>
    <app-whatsapp-button />
    <app-footer></app-footer>
  </div>
</template>

<script>
import footer from "../components/footer";
import Lottie from "vue-lottie";
import whatsappButton from "../components/whatsappButton.vue";
import * as animationData from "../assets/62884-boy-marketing.json";
import startCareerPlans from "../components/plansCardsInHomePage.vue";
import values from "../components/values";
export default {
  components: {
    appStartCareerPlans: startCareerPlans,
    appFooter: footer,
    lottie: Lottie,
    appValues: values,
    appWhatsappButton: whatsappButton,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,

      firstPlan: {},
      secondPlan: {},
      thirdPlan: {},
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
  computed: {
    circleData() {
      if (this.$vuetify.rtl) {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "الزمن",
            description: `كل خطوة في عملنا مصممة ليتم تنفيذها بزمن قياسي وبأقل مجهود ممكن.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "الشفافية",
            description: `نعتمد في كافة أعمالنا على شفافية مطلقة مع كافة الجهات المعنية والمستفيدة.`,
          },
          {
            icon: "fa fa-globe",
            title: "التواصل",
            description: `بشبكة واسعة من الشركات على امتداد الجغرافية الإماراتية سنعمل على نموها بشكل مستمر.`,
          },
        ];
        return circleDataArr;
      } else {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "Time",
            description: `We value your time, that is why our dedicated system
Connects your CV with employers in record time.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "Transparency",
            description: `We guarantee absolute transparency with all stakeholders and beneficiaries.`,
          },
          {
            icon: "fa fa-globe",
            title: "Connecting",
            description: `We are continuously expanding our database with a wide network of companies within the UAE.`,
          },
        ];
        return circleDataArr;
      }
    },
    cardData() {
      let cardDataArr = [
        {
          icon: "fa fa-upload uploadIcon",
          headTitle: "STEP 1",
          title: "Upload your CV",
          description: `Highlight your skills and experience.`,
        },
        {
          icon: "fa fa-hand-pointer-o",
          title: "Choose your package",
          headTitle: "STEP 2",
          description: `Choose the plan that suits you best.`,
        },
        {
          icon: "fa fa-check",
          title: "Start connecting!",
          headTitle: "STEP 3",
          description: `Start engaging with potential employers today!`,
        },
      ];
      if (this.$vuetify.rtl) {
        cardDataArr = [
          {
            icon: "fa fa-upload uploadIcon",
            headTitle: "الخطوة ١",
            title: "قم بتحميل السيرة الذاتية",
            description: `حدد مهاراتك وخبراتك.`,
          },
          {
            icon: "fa fa-hand-pointer-o",
            title: `اختر باقتك`,
            headTitle: "الخطوة ٢",
            description: `اختر الباقه التي تناسبك الأفضل.`,
          },
          {
            icon: "fa fa-check",
            title: "ابدأ بالتواصل",
            headTitle: "الخطوة ٣",
            description: "ابدأ بالتواصل مع أصحاب العمل المحتملين اليوم!",
          },
        ];
      }

      return cardDataArr;
    },
    watchLang() {
      let texts = {
        homeText1: `Ready to take the next step in your career? `,
        homeText2: `Upload your CV to unlock exclusive job opportunities and connect with over 300,000 employers in the U.A.E`,
        homeText3: `don’t miss out on your dream job!`,
        homeButtonText: "CONNECT NOW!",
        howWeWorkHead: " THE  CONNECTION  PROCESS  ",
        howWeWorkGeneralText: `CONNECT WITH EMPLOYERS IN THE UAE WITH
3 EASY-FAST STEPS`,
        ourValue: "VALUES",
      };
      if (this.$vuetify.rtl) {
        texts.homeText1 = "هل أنت مستعد للخطوة التالية في مسيرتك المهنية؟";
        texts.homeText2 =
          "قم بتحميل سيرتك الذاتية لفتح فرص عمل حصرية والتواصل مع أكثر من 300,000 صاحب عمل في الإمارات العربية المتحدة.";
        texts.homeText3 = "لا تفوت فرصتك في الحصول على وظيفة أحلامك!";
        texts.homeButtonText = "ابدأ رحلتك";
        texts.howWeWorkHead = "كيف تعمل المنصة";
        texts.howWeWorkGeneralText = `تواصل مع أصحاب العمل في الإمارات العربية المتحدة من خلال 
٣ خطوات سهلة وسريعة`;
        texts.ourValue = "قيمنا";
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>
<style>
.scroll {
  color: inherit !important;
}
html {
  scroll-behavior: smooth;
}
.homePage__section1Wrapper,
.homePage__section3Wrapper {
  box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
}
.homePage__section1TextWrapper,
.homePage__section1LottieWrapper {
  font-family: "TRENCH", Tajawal, sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homePage__section1TextWrapper h1 {
  font-size: 3rem;
  font-weight: bold;
}
.homePage__section1TextWrapper h5 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 0;
  font-family: "Roboto";
}
.homePage__section1Span {
  color: #f39f3c;
  font-size: 3.5rem;
}

.homePage__button {
  text-transform: none !important;
  transition: 0.5s !important;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 700;
}
.homePage__button .v-btn__content {
  font-size: 20px;
}

.homePage__button:hover {
  background: white !important;
  color: #f39f3c !important;
  transition: 0.5s;
}
.homePage__section2Wrapper {
  color: #599ca7;
}
.homePage__section2Wrapper,
.homePage__section3Wrapper {
  padding: 30px 0;
}
.homePage__section2Wrapper h1 {
  color: #f39f3c;
}
.homePage__section2Wrapper h1,
.homePage__section3Wrapper h1 {
  text-align: center;
  margin: 10px;
}
.section2--generalText {
  text-align: center;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 500;
  color:black
}
.homePage__howWeWorkCard {
  box-shadow: 5px 5px 10px 3px #88888861;
  border-radius: 20px;
  height: 255px;
  padding: 52px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.homePage__howWeWorkCard h3 {
  font-size: 25px;
  font-family: "TRENCH", Tajawal, sans-serif;
  font-weight: bold;
}
.homePage__howWeWorkCard i {
  font-size: 25px;
  margin-top: 13px;
  margin-bottom: 13px;
}
.homePage__howWeWorkCard h2{
  font-size: 20px;
  color: black;
     font-weight: 700;
}
.homePage__howWeWorkCard
h6 {
  font-size: 16px;
  color: black;
   font-weight: 700;
}

.homePage__howWeWorkCard:hover .cardIcon {
  color: rgba(33, 123, 138, 1);
  transition: 0.5s;

  transform: scale(1.2);
}
.homePage__howWeWorkCard:hover {
  transform: translateY(-10px);
}
.cardIcon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: rgba(33, 123, 138, 1);
  transition: 0.5s;
}
.section3__OptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section3__OptionWrapper p {
  font-size: 15px;
}
.homePage__section2Wrapper p {
  margin:auto;
  margin-bottom: 43px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  max-width: 400px;
}
.section3__OptionWrapper:hover .section3__circle i {
  color: rgba(33, 123, 138, 1);
  transition: 0.5s;
  transform: scale(1.2);
}
.section3__OptionWrapper h3 {
  text-align: center;
  padding: 0 23px;
}

.section3__circle {
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.ourValue-cardParagraph {
  /* white-space: pre-wrap; */
  text-align: center;
  max-width:85%;
}
.section3__circle i {
  color: #f39f3c;
  font-size: 3.5rem;
  transition: 0.5s;
}
.homeButtonRTL {
  font-size: 1.2rem !important;
}

@media only screen and (min-width: 720px) {
  .homePage__section1TextWrapper h1 {
    font-size: 4.5rem;
  }
  .homePage__section1TextWrapper h5 {
    text-align: center;
  }
  .homePage__section1Span {
    font-size: 4.5rem;
  }
  .section2--generalText {
    padding: 0;
    width: 60%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 600px) {
  .home-page .startCareerPlansImage {
    padding-top: 181px !important;
  }
  .imgoffer {
    width: 5rem !important;
  }
  .headerSection {
    font-size: 42px !important;
  }
  .headerParaghraph {
    font-size: 17px !important;
    color:black !important
  }
  .ab {
    top: 58px;
  }
  .footer--wrapper .footer-sections {
    text-align: center;
    justify-content: center;
  }
  .footer--col p {
    justify-content: center;
  }
}
</style>
