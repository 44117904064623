import { render, staticRenderFns } from "./paymentFailure.vue?vue&type=template&id=b24d0696&scoped=true&"
import script from "./paymentFailure.vue?vue&type=script&lang=js&"
export * from "./paymentFailure.vue?vue&type=script&lang=js&"
import style0 from "./paymentFailure.vue?vue&type=style&index=0&id=b24d0696&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b24d0696",
  null
  
)

export default component.exports