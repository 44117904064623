import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    stageCounter: 1,
    attemptToken: null,
    showLoadingModal: false,
    currency: "",
    paymentAuthorization: "",
    categories: [],
    countries: [],
    userFormData: {
      userName: "",
      email: "",
      country: null,
      city: null,
      category: "",
      cv: null,
    }
  },
  mutations: {
    setUserData(state, userData) {
      state.userFormData.userName = userData.name
      state.userFormData.email = userData.email
      state.userFormData.country = userData.country
      state.userFormData.city = userData.city
      state.userFormData.category = userData.category
      state.userFormData.cv = userData.cv
    },
    resetUserData(state) {
      state.userFormData.userName = ""
      state.userFormData.email = ""
      state.userFormData.country = null
      state.userFormData.city = null
      state.userFormData.category = null
      state.userFormData.cv = null
    },
    firstStageDone(state, stageCounterStatus) {
      state.stageCounter = stageCounterStatus
    },
    secondStageDone(state, stageCounterStatus) {
      state.stageCounter = stageCounterStatus
    },
    attemptToken(state, token) {
      state.attemptToken = token
    },
    displayLoadingModal(state, loadingStatus) {
      state.showLoadingModal = loadingStatus
    },
    setCountriesData(state, countries) {
      state.countries = countries
    },
    setCategoriesData(state, categories) {
      state.categories = categories
    }
  },
  actions: {
    setCountries({ commit }, countries) {
      commit('setCountriesData', countries)
    },
    setCategories({ commit }, categories) {
      commit('setCategoriesData', categories)
    },

    async firstStageDone({ commit }, userData) {
      try {
        commit('setUserData', userData)
        window.scrollTo(0, 0);
        // commit('displayLoadingModal', true)
        let form = new FormData();
        form.append("name", userData.name);
        form.append("email", userData.email);
        form.append("city_id", userData.city.id);
        form.append("category_id", userData.category.id);
        form.append("cv", userData.cv);
        // if (userData.cover_letter) {
        //   form.append("cover_letter", userData.cover_letter);
        // }
        const response = await axios.post('/attempt', form)
        commit('firstStageDone', 2)
        commit('attemptToken', response.data.data.attempt_token)
      }
      catch (err) {
        commit('displayLoadingModal', false)
      }



    },
    async secondStageDone({ commit, state }, checkOut) {
      try {
        window.scrollTo(0, 0);
        commit('displayLoadingModal', true)
        const paymentResponse = await axios.post('/tokens', {
          "number": checkOut.cardNumber,
          "expiry_month": checkOut.expiryMonth,
          "expiry_year": checkOut.expiryYear,
          "cvv": checkOut.ccv,
          "type": "card"
        }, {
          baseURL: "https://api.checkout.com",
          headers: {
            Authorization: state.paymentAuthorization
          }
        })
        const response = await axios.patch(`/attempt/${state.attemptToken}/plan`, {
          plan_id: checkOut.selectedPlan,
          payment_token: paymentResponse.data.token,
          currency: state.currency
        })
        commit('resetUserData')
        commit('secondStageDone', 3)
        console.log('finally all done');
      }
      catch (err) {
      }
      finally {
        commit('displayLoadingModal', false)

      }

    },
    async createPaymentLink({ state }, payload) {
      try {
        // Send the payment details to the backend
        const response = await axios.post('/create-payment-link', {
          amount: payload.amount,
          amount_currency: payload.amount_currency,
          firstname: payload.firstname,
          lastname: payload.lastname,
          email: payload.email,
          return_url: payload.return_url,
          planId: payload.planId,
        });
        console.log(response.data);
        // Return the payment link from the backend
        return response.data;
      } catch (error) {
        console.error('Error creating payment link', error);
        throw error;
      }
    },
  },
  getters: {
    getUserFormData(state) {
      return state.userFormData
    },
    getStageCounter(state) {
      return state.stageCounter
    },
    getAttemptToken(state) {
      return state.attemptToken
    },
    getLoadingModalStatus(state) {
      return state.showLoadingModal
    },
    getCountries(state) {
      return state.countries
    },
    getCategories(state) {
      return state.categories
    },
  },
  modules: {
  }
})
