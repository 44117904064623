<template>
  <div class="payment-failure" :class="{'rtl': watchLang === 'ar'}">
    <div class="content">
      <h1>{{ failureTitle }}</h1>
      <p>{{ failureMessage }}</p>
      <button @click="retryPayment">{{ retryText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    // Computed property that returns the language setting
    watchLang() {
      // Example logic to get the language, it could be based on store, localStorage, or props
      return this.$vuetify.rtl ? 'ar' : 'en'; // Assuming $vuetify.rtl determines RTL (for Arabic)
    },
    
    // Dynamically switch titles and messages based on the language
    failureTitle() {
      return this.watchLang === 'ar' ? 'فشل الدفع' : 'Payment Failed';
    },
    failureMessage() {
      return this.watchLang === 'ar'
        ? 'نعتذر، لقد فشلت عملية الدفع الخاصة بك. الرجاء المحاولة مرة أخرى.'
        : 'We’re sorry, your payment has failed. Please try again.';
    },
    retryText() {
      return this.watchLang === 'ar' ? 'إعادة المحاولة' : 'Retry Payment';
    }
  },
  methods: {
    retryPayment() {
      // Logic to retry payment or redirect the user back to the payment page
      this.$router.push("/StartYourCarrer"); // Example route to retry payment
    }
  }
};
</script>

<style scoped>
.payment-failure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8d7da;
}

.content {
  text-align: left; /* Default to LTR */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.rtl .content {
  direction: rtl; /* RTL for Arabic */
  text-align: right;
}

h1 {
  color: #721c24;
  font-size: 24px;
}

p {
  color: #721c24;
  font-size: 16px;
}

button {
  background-color: #f39c12;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #e67e22;
}
</style>
