<template>
   <div>
      <div class="plan-card__wrapper">
         <!-- Plan Card Header -->
         <div class="plan-card__header" :style="`background:${planInfo.color}`">
            <div v-if="localeText == 'en'" class="card-header__icon-wrapper">
               {{ planInfo.name }}
            </div>
            <div v-else class="card-header__icon-wrapper">
               {{ planInfo.name_ar }}
            </div>
         </div>

         <!-- Plan Card Body -->
         <div class="plan-card__body-wrapper">
            <!-- Plan Prices based on currency -->
            <div class="plan-card__price" v-if="currencySign == 'USD'">
               <p class="old">
                  <span class="price">{{ planInfo.price }} </span>
                  <span>{{ currencySign }}</span>
               </p>
               <p class="new">{{ planInfo.payment_price }}</p>
            </div>
            <div class="plan-card__price" v-else-if="currencySign == 'AED'">
               <p class="old">
                  <span class="price">{{ planInfo.price_aed }} </span>
                  <span>{{ currencySign }}</span>
               </p>
               <p class="new">{{ planInfo.real_price_aed }}</p>
            </div>
            <img src="@/assets/suite.svg" class="card-header__icon" />

            <!-- Plan Description -->
            <div class="plan-card__description" v-if="localeText == 'en'">
               {{ planInfo.description }}
            </div>
            <div class="plan-card__description" v-else>
               {{ planInfo.description_ar }}
            </div>

            <!-- Plan Icon -->
         </div>

         <!-- Apply Plan Button -->
         <v-btn color="rgb(243, 159, 60)" class="align-self-flex-start" width="80%" style="text-transform: unset"
            rounded @click="applyPlan">
            {{ $t('planApply') }}
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      planInfo: Object,
      oldPrice: Object,
   },
   data() {
      return {
         currencySign: this.$store.state.currency,
         paymentLink: null,
      };
   },
   methods: {
      async applyPlan() {
         try {
            let modalTitle = this.$vuetify.rtl
               ? "الرجاء الانتظار، يتم إنشاء رابط الدفع"
               : "Please wait, creating the payment link";
            this.$swal.fire({
               title: modalTitle,
               showConfirmButton: false,
               didOpen: () => {
                  this.$swal.showLoading();
               },
            });

            // Fetch user data from the store (to be sent in the request)
            const userData = this.$store.getters.getUserFormData;
            var price = (this.currencySign == 'USD') ? this.planInfo.payment_price : this.planInfo.real_price_aed;
            // Send the required fields to the Vuex action to create payment link
            const response = await this.$store.dispatch("createPaymentLink", {
               planId: this.planInfo.id,                 // Plan ID
               amount: price,              // Amount
               amount_currency: this.currencySign,       // Currency
               firstname: userData.userName.split(' ')[0],  // First Name from userData
               lastname: userData.userName.split(' ')[1] || '',  // Last Name from userData
               email: userData.email,                    // Email
               return_url: `https://connectmeae.com/payment-success?token=${this.$store.state.attemptToken}&planid=${this.planInfo.id}&currency=${this.currencySign}`, // Return URL
            });
            console.log(response);
            const paymentURL = response.data.payment_url;
            
            if (response && paymentURL) {
               console.log("Payment URL:", paymentURL);
               window.location.href = paymentURL;  // Redirect user to the payment URL
            } else {
               console.error("payment_url not found in response.");
            }
            this.$swal.close();
         } catch (error) {
            console.error("Error creating payment link:", error);
            this.$swal.fire({
               title: "Error",
               text: "Failed to create payment link. Please try again later.",
               icon: "error",
            });
         }
      },
   },
   computed: {
      localeText() {
         return this.$i18n.locale;
      },
   },
};
</script>
<style>
.plan-card__wrapper {
   width: 279px;
   height: 377px;
   background: white;
   padding-bottom: 10px;
   box-shadow: 5px 5px 10px 3px #88888861;
   display: flex;
   flex-direction: column;
   transition: all 0.3s;
   align-items: center;
}

.plan-card__wrapper .v-btn__content {
   font-size: 20px;

}

.plan-card__wrapper:hover {
   transform: translateY(-13px) scale(1.02);
}

.plan-card__header {
   width: 100%;
   padding: 13px 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   position: relative;
   align-items: center;
}

.plan-card__header::after {
   content: "";
   width: 100%;
   height: 100%;
   position: absolute;
   background: #ffffff59;
   z-index: 1;
}

.plan-card__body-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;

   padding-top: 26px;
   align-items: center;
   height: 59%;
}

.card-header__icon-wrapper {
   color: black;
   font-weight: bold;
   font-size: 22px;
   z-index: 2;
   
}

.card-header__icon {
   width: 28px;
}

.plan-card__name {
   font-size: 1.3rem;
   padding-top: 3%;
   color: black;
   font-weight: bold;
}

.plan-card__price {
   color: black;
   font-size: 1.6rem;
   font-weight: bold;
   /* padding-top: 10%; */
}

.plan-card__price p {
   margin: 0;
   font-size: 37px;
   color: #f39f3c;
   text-align: center;
   line-height: 39px;
}

.plan-card__price .old {
   position: relative;
   width: max-content;
   margin: auto;
}

.plan-card__price .new {
   color: #e70d0a;
}

.plan-card__price .price {
   position: relative;

   padding-right: 0;
   margin: 0;
}

.plan-card__price .price::after {
   content: ' ';
   display: block;
   width: 100%;
   border-top: 4px solid #e70d0a;
   position: absolute;
   top: 50%;
   transform: translate(0, -50%) rotate(-15deg);
   left: 0;
}

.plan-card__description {
   color: black;
    text-align: center;
    padding: 0 10px;
    margin: 10px 0;
    font-weight: 600;
}

.plansButtonRTL {
   font-size: 1.2rem !important;
}
</style>
{
   color: black;
    text-align: center;
    padding: 0 10px;
    margin: 10px 0;
    font-weight: 600;
}