<template>
   <div>
      <div class="startCareerPlansImage">
         <v-container>
            <v-row>
               <v-icon size="40" @click="goToStepOne" v-if="localeText == 'en'"
                  >mdi-arrow-left-circle</v-icon
               >
               <v-icon size="40" @click="goToStepOne" v-else>mdi-arrow-right-circle</v-icon>
               <div class="plan-cards__wrapper">
                  <app-plan-card
                     class="plan-card__mobile"
                     :planInfo="firstPlan"
                     :oldPrice="{ usd: '21.99', aed: '79.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
                  <!-- class="plan__gold-card" -->
                  <app-plan-card
                     class="plan-card__mobile"
                     :planInfo="secondPlan"
                     :oldPrice="{ usd: '24.99', aed: '89.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
                  <app-plan-card
                     :planInfo="thirdPlan"
                     :oldPrice="{ usd: '26.99', aed: '99.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
               </div>

               <v-col cols="12" md="6">
                  <div class="plans__buttonWrapper"></div>
               </v-col>
            </v-row>
         </v-container>
      </div>
   </div>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import axios from 'axios';
import planCard from '../components/planCard.vue';
export default {
   components: {
      appPlanCard: planCard,
   },
   data() {
      return {
         plans: [],

         firstPlan: {},
         secondPlan: {},
         thirdPlan: {},
      };
   },
   methods: {
      goToStepOne() {
         this.$store.state.stageCounter = 1;
      },
      sendPlan(checkOut) {
      // Create payment link and open it
      this.$store.dispatch("createPaymentLink", checkOut);
    },
   },
   computed: {
      localeText() {
         return i18n.locale;
      },
      plansBtnDisabled() {
         if (this.checkOut.selectedPlan == null) return true;
      },
      watchLang() {
         if (this.$vuetify.rtl == true) {
            return 'تسجيل';
         } else {
            return 'Register';
         }
      },
   },
   async created() {
      try {
         const plans = await axios.get('/plan');
         this.plans = plans.data.plans;
         this.firstPlan = this.plans[0];
         this.secondPlan = this.plans[1];
         this.thirdPlan = this.plans[2];
         if (!this.$store.getters.getLoadingModalStatus) {
            this.$swal.close();
         }
      } catch (err) {}
   },
};
</script>

<style>
.plan-cards__wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
/* .plan__gold-card {
  margin: 30px 0;
} */
.plan-card__mobile {
   margin-bottom: 5%;
}
.v-messages__message {
   color: unset;
}

.plans__buttonWrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}
.startCareerPlansImage {
   background: url('../assets/stil-vdaJJbls3xE-unsplash.jpg') 100% / cover;
   width: 100%;
min-height: calc(100vh - 60px);
   padding-top: 170px;
   box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
}
@media only screen and (min-width: 960px) {
   .plan-card__mobile {
      margin-bottom: 0;
   }
   .plan-cards__wrapper {
      padding: 0 40px;
      flex-direction: row;
      justify-content: space-around;
   }
}
@media only screen and (min-width: 1224px) {
   .plan-card__mobile {
      margin-bottom: 0;
   }
   .plan-cards__wrapper {
      padding: 0 100px;
      flex-direction: row;
      justify-content: space-around;
   }
   .plans__buttonWrapper {
      justify-content: start;
   }
   /* .plan__gold-card {
    transform: translateY(-30px);
  } */
}
</style>
