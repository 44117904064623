import Vue from 'vue'
import VueRouter from 'vue-router'
import aboutUs from '../views/aboutUs.vue'
import Home from '../views/Home.vue'
import paymentFailure from '../views/paymentFailure.vue'
import paymentSuccess from '../views/paymentSuccess.vue'
import policy from '../views/policy'
import startCareer from '../views/startCareer.vue'
import terms from '../views/terms'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,

  },
  {
    path: '/TermsAndConditions',
    component: terms,
  },
  {
    path: '/PrivacyAndPolicy',
    component: policy,
  },
  {
    path: '/StartYourCarrer',
    name: 'StartYourCarrer',
    component: startCareer
  },
  {
    path: '/AboutUs',
    name: 'About Us',
    component: aboutUs
  },
  {
    path: '/payment-success',
    name: 'Payment Success',
    component: paymentSuccess
  },
  {
    path: '/payment-failure',
    name: 'Payment Failure',
    component: paymentFailure
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
