<template>
  <div>
    <div class="aboutUs--section1">
      <h1 class="headerSection">
        {{ watchLang.aboutUsHead }}
        <span> <span class="sectionSpan">C</span>ONNECT ME</span>
      </h1>
      <v-btn
        rounded
        x-large
        color="white"
        class="aboutUs__button animate__animated animate__swing"
        :class="{ homeButtonRTL: $vuetify.rtl }"
        @click="$router.push('/StartYourCarrer')"
        >{{ watchLang.abutUsButtonText }}</v-btn
      >
    </div>
    <div class="aboutUs--section2">
      <v-container>
        <v-row>
          <v-col sm="6" cols="12" class="aboutUs_section2TextCol">
            <!-- <h1 :class="{ aboutUsArText: $vuetify.rtl }">
              {{ watchLang.aboutUs }}
            </h1> -->
            <p
              :class="{
                aboutUsArParagraph: $vuetify.rtl,
              }"
            >
              <span> {{ watchLang.aboutUsText1 }}</span>
              <br />
              <span> {{ watchLang.aboutUsText2 }}</span>
              <br />
              <span> {{ watchLang.aboutUsText3 }}</span>
              <br />
              <br />
              <span> {{ watchLang.aboutUsText4 }}</span>
            </p>
          </v-col>
          <v-col sm="6" cols="12" class="aboutUs_section2ImageCol">
            <div class="section2-imageWrapper">
              <img
                src="@/assets/Connectme.png"
                alt="..."
                class="section2--image"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
        <app-values></app-values>
    <div class="aboutUs--section3">
      <h1 class="headerSection">{{ watchLang.ourWorkFlow }}</h1>
      <v-container>
        <div class="missions">
          <div
            v-for="(item, index) in ourWork"
            :key="index"
            class="section3--col"
          >
            <div class="section3--paragraph">
              <i class="section3--checkIcon">
                <img src="@/assets/Connectme.png" alt="" />
              </i>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <app-whatsapp-button />
    <app-footer></app-footer>
    <!-- <app-partners></app-partners> -->
  </div>
</template>

<script>
import footer from "../components/footer";
import values from "../components/values";
import partners from "../components/partners.vue";
import whatsappButton from "../components/whatsappButton.vue";
export default {
  components: {
    appFooter: footer,
    appValues: values,
    appPartners: partners,
    appWhatsappButton: whatsappButton,
  },
  data() {
    return {};
  },
  computed: {
    ourWork() {
      let ourWork = [
        {
          description: `Creating a connection between job seekers and employers using our dedicated team and technology.`,
        },
        {
          description: `Connecting Job seekers at an affordable cost and in record time.`,
        },
        {
          description:
            "Facilitating the time consuming process of searching for a job by making it efficient & effective.",
        },
      ];

      if (this.$vuetify.rtl == true) {
        ourWork = [
          {
            description: `خلق رابطة احترافية بين الباحثين عن عمل والمؤسسات الحديثة باعتماد أحدث الطرق والتقنيات، وتأمين أعمال أفضل لحياة
أفضل.`,
          },
          {
            description: `تسهيل عملية البحث عن عمل ضمن أفضل البيئات ولجميع الأفراد.`,
          },
          {
            description: `إيصال أصحاب المهارات إلى الأماكن التي تبحث عنهم، بأسرع وقت وأقل تكلفة.`,
          },
        ];
      }
      return ourWork;
    },
    watchLang() {
      let texts = {
        aboutUsSmallText: "Any Location, Any Time",
        abutUsButtonText: "CONNECT NOW!",
        aboutUsHead: "Connect with",
        aboutUs: "About us",
        ourWorkFlow: "Our mission",
        aboutUsText1: `Every career journey begins with a dream—and at Connect Me, we’re here to help you turn that dream into reality. We understand that seeking a job in the UAE can be challenging, which is why we focus on connecting talented individuals with employers eager to find the right fit.`,
        aboutUsText2: `When you share your CV with us, our team takes the time to understand your unique story and aspirations. `,
        aboutUsText3: `We’re committed to guiding you through the job market, providing support that goes beyond simple distribution.`,
        aboutUsText4:
          "Join us at Connect Me, and let’s navigate your path to success together. Your next opportunity awaits!",
        //         aboutUsText: `Every career journey begins with a dream—and at Connect Me, we’re here to help you turn that dream into reality. We understand that seeking a job in the UAE can be challenging, which is why we focus on connecting talented individuals with employers eager to find the right fit.
        // When you share your CV with us, our team takes the time to understand your unique story and aspirations.
        // We’re committed to guiding you through the job market, providing support that goes beyond simple distribution.
        // Join us at Connect Me, and let’s navigate your path to success together. Your next opportunity awaits!
        // `,
      };
      if (this.$vuetify.rtl == true) {
        texts.aboutUsSmallText = "في أي مكان و في أي وقت";
        texts.abutUsButtonText = "تواصل الان";
        texts.aboutUsHead = "تواصل مع";
        texts.aboutUs = "من نحن";
        texts.ourWorkFlow = "مهمتنا";
        texts.aboutUsText1 = `تبدأ كل مسيرة مهنية بحلم 
وفي
Connect Me
`;
        texts.aboutUsText2 = `نحن هنا لمساعدتك في تحويل هذا الحلم إلى واقع. نحن نفهم أن البحث عن وظيفة في الإمارات قد يكون تحديًا، ولهذا السبب نركز على ربط الأفراد الموهوبين بأصحاب العمل الذين يتطلعون للعثور على الشخص المناسب.
`;
        texts.aboutUsText3 = `عندما تشارك سيرتك الذاتية معنا، يأخذ فريقنا الوقت لفهم قصتك الفريدة وطموحاتك. نحن ملتزمون بتوجيهك خلال سوق العمل، وتقديم الدعم الذي يتجاوز مجرد التوزيع.
انضم إلينا في
!Connect Me
`;
        texts.aboutUsText4 = `ولنجعل مسيرتك نحو النجاح معًا. فرصتك التالية تنتظرك!`;
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>

<style>
.headerSection {
  font-family: "TRENCH", Tajawal, sans-serif;
  font-weight: bold;
  font-size: 68px;
  text-align: center;
}
.headerSection .sectionSpan {
  color: #599ca7;
}
.aboutUs--section1 {
  width: 100%;
  text-align: center;
  padding: 50px 0;
  background: #f39f3c;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutUs--section1 h1 {
  font-family: "TRENCH", Tajawal, sans-serif;
  font-weight: bold;
  /* font-size: 62px; */
}
.aboutUs--section2 h1 {
  font-family: "TRENCH", Tajawal, sans-serif;
  font-weight: bold;
  font-size: 53px;
  color: #f39f3c;
}
.aboutUs--section3 h1 {
  color: #fff;
}
.aboutUs--section3 .missions {
  width: fit-content;
  margin: auto;
}
.aboutUs--section2 p {
  margin-bottom: 0;
  font-weight: 600;
}
.aboutUs__button {
  color: #f39f3c !important;
  text-transform: none;
  transition: 0.5s;
}
.aboutUs__button:hover {
  background: #599ca7 !important;
  transition: 0.5s;
  color: white !important;
}
.aboutUs--section2 {
  margin: 100px 0;
  color: #599ca7;
}
.aboutUs_section2TextCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutUs_section2TextCol p {
  text-align: center;
  color: black;
  font-size: 15px;
  line-height: 18px;
}
.aboutUs_section2TextCol p > span:last_child {
  margin: 10px 0 0 0;
}
.aboutUs_section2ImageCol {
  display: flex;
  justify-content: center;
}
.section2-imageWrapper {
  height: auto;
  width: 250px;
}
.section2--image {
  width: 100%;
}
.aboutUs--section3 {
  margin-top: 0;
  padding: 50px 0;
  width: 100%;
  background: #f7af59;
  text-align: center;
}
.section3--col:hover .section3--checkIcon {
  color: green;
  transition: 0.5s;
  transform: scale(1.2);
}
.section3--singleContent {
  display: flex;
  align-items: center;
}
.section3--checkIcon {
  font-size: 1.1rem;
  padding-right: 10px;
  transition: 0.5s;
}
.section3--checkIcon img {
  width: 25px;
}
.section3--paragraph {
  text-align: start;
  display: flex;
  font-size: 18px;
  margin: 14px 0;
}
.section3--paragraph p {
  margin-bottom: 0;
}
.aboutUsArText,
.aboutUsArParagraph {
  text-align: center;
}
.aboutUsArParagraph {
  font-size: 1.1rem;
}
.section3--checkIconAr {
  padding-right: 0;
  padding-left: 10px;
}
@media only screen and (max-width: 457px) {
  .section3--checkIcon {
    font-size: 1.2rem;
  }
}
</style>
