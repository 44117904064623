<template>
  <div class="homePage__section3Wrapper">
    <h1 class="headerSection">{{ watchLang.ourValue }}</h1>
    <v-container>
      <v-row>
        <v-col
          md="4"
          cols="12"
          v-for="(data, index) in circleData"
          :key="index"
        >
          <div class="section3__OptionWrapper">
            <div class="section3__circle">
              <i :class="data.icon" aria-hidden="true"></i>
            </div>
            <h3>{{ data.title }}</h3>
            <p class="ourValue-cardParagraph">
              {{ data.description }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {
    circleData() {
      if (this.$vuetify.rtl) {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "الزمن",
            description: `نضمن أن تُنجز كل خطوة في عملنا بكفاءة عالية وفي أسرع وقت ممكن.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "الشفافية",
            description: `نؤمن بأهمية الشفافية المطلقة في جميع تعاملاتنا مع الجهات المعنية والمستفيدة.`,
          },
          {
            icon: "fa fa-globe",
            title: "التواصل",
            description: `نسعى باستمرار لتوسيع شبكة علاقاتنا مع الشركات في جميع أنحاء الإمارات، مما يعزز فرص التواصل والنمو.`,
          },
        ];
        return circleDataArr;
      } else {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "Time",
            description: `We respect your time, which is why our dedicated team connects your CV with employers quickly and efficiently.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "Transparency",
            description: `We prioritize absolute transparency with all our stakeholders and beneficiaries, ensuring clear communication every step of the way.`,
          },
          {
            icon: "fa fa-globe",
            title: "Connecting",
            description: `We are committed to continuously expanding our network, connecting you with a diverse range of employers across the UAE.`,
          },
        ];
        return circleDataArr;
      }
    },

    watchLang() {
      let texts = {
        ourValue: "VALUES",
      };
      if (this.$vuetify.rtl) {
        texts.ourValue = "قيمنا";
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>
<style>
.ourValue-cardParagraph {
  font-size: 1px;
  max-width: 88%;
  line-height: 16px;
  font-weight: 800;
  font-family: "Roboto";
}
</style>
