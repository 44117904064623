<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <p v-bind="attrs" v-on="on">
        {{ $t("policy.title") }}
      </p>
    </template>
    <v-card class="p-2">
      <v-card-title>
        <h2
          class="font-weight-medium pl-3 black--text"
          :class="{ 'pr-3': $vuetify.rtl }"
        >
          {{ $t("policy.title") }}
        </h2>
      </v-card-title>
      <v-card-text class="black--text">
        {{ $t("policy.generalText") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="dialog = false" class="modalOkeyButton">
          {{ $t("terms.buttonText") }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.v-application p {
  transition: 0.5s;
  
}
.v-application p:hover {
  color: #f39f3c;
}
.v-dialog > .v-card > .v-card__text {
  font-size: 1rem;
  text-align: start;
  font-weight: bold;
  white-space: pre-line;
  padding: 20px !important;
}
.modalOkeyButton {
  font-size: 1.2rem !important;
  color: #599ca7;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}
.modalOkeyButton:hover {
  background: lightgray;
}
</style>