<template>
   <div class="partners">
         <!-- <h1  class="headerSection">{{ watchLang.partners }}</h1> -->
     <v-container>
<!-- <v-row>
    <v-col
      md="4"
            cols="12"
         
   >
   <div  class="img">
      <img src="@/assets/eventy.png" alt=""/>
   </div>

   </v-col>
   
   <v-col
      md="4"
            cols="12"
         
   >
   <div  class="img">
      <img src="@/assets/check-out.png" alt=""/>
   </div>

   </v-col>
  <v-col
      md="4"
            cols="12"
         
   >
   <div class="img">
      <img src="@/assets/pc.png" alt=""/>
   </div>

   </v-col>
</v-row> -->
     </v-container>
 
   </div>
</template>
<script>
export default {
  computed :{
    
  
  watchLang() {
      let texts = {
     
        partners: "Partners with",
      };
      if (this.$vuetify.rtl) {
      
        texts.partners = "بالتعاون مع";
      }
      return texts;
    },
  },
   created() {
    this.$store.state.stageCounter = 1;
  },
 
};
</script>
<style scoped>
.partners{
   padding: 20px ;
}
.partners h1{
   color: #f39f3c;

    margin: 10px;
    
    margin-bottom: 60px;
  
   
}
.partners .row{
   align-items:center;

}
.partners .img{
    padding: 0 67px;
}
.partners img{
    width: 100%;
}
</style>
