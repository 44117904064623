<template>
  <div>
    <div class="plan-card__wrapper">
      <div class="plan-card__header" :style="`background:${planInfo.color}`">
        <div v-if="localeText == 'en'" class="card-header__icon-wrapper">
     {{ planInfo.name }}
        </div>
        <div v-else class="card-header__icon-wrapper">
          {{ planInfo.name_ar }}
        </div>
      </div>
      <div class="plan-card__body-wrapper">
        <div class="plan-card__price" v-if="offer">
          <p class="old">
            <span class="price">{{ planInfo.price_aed }} </span><span>AED</span>
          </p>
          <p class="new">{{ planInfo.real_price_aed }}</p>
        </div>
        <div class="plan-card__price" v-else>
          <p>{{ planInfo.price_aed }} AED</p>
        </div>

        <!-- <img :src="planInfo.icon" class="card-header__icon" /> -->
        <img class="card-header__icon" src="@/assets/suite.svg" />
        <div class="plan-card__description" v-if="localeText == 'en'">
          {{ descriptionEditing(planInfo.description) }}
        </div>
        <div class="plan-card__description" v-else>
          {{ descriptionEditing(planInfo.description_ar) }}
        </div>
      </div>
      <v-dialog v-model="dialog" width="500px">
        <template v-slot:activator="{ on, attrs }">
          <router-link to="/StartYourCarrer">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="rgb(243 159 60)"
              class="align-self-flex-start"
              style="text-transform: unset"
              rounded
              >{{ $t("getStart") }}</v-btn
            >
          </router-link>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
export default {
  data() {
    return {
      currencySign: this.$store.state.currency,
      dialog: false,
      offer: true,
      checkOut: {
        selectedPlan: null,
        cardName: "",
        cardNumber: "",
        ccv: "",
        expiryYear: "",
        expiryMonth: "",
      },
    };
  },
  props: {
    planInfo: Object,
    oldPrice: Object,
  },
  methods: {
    descriptionEditing(x) {
      return this.numberWithCommas(this.companiesToEmployers(x));
    },
    companiesToEmployers(x) {
      return x.replace("companies", "employers");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectPlan() {
      this.checkOut.selectedPlan = this.planInfo.id;
      // console.log("OK !", this.checkOut.selectedPlan);
    },
    emitPaymentInfo() {
      this.$emit("paymentInfo", this.checkOut);
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.checkOut.cardName = "";
        this.checkOut.cardNumber = "";
        this.checkOut.ccv = "";
        this.checkOut.expiryYear = "";
        this.checkOut.expiryMonth = "";
      }
    },
  },
  computed: {
    localeText() {
      return i18n.locale;
    },
    paymentBtn() {
      if (
        !this.checkOut.cardName ||
        !this.checkOut.cardNumber ||
        !this.checkOut.ccv ||
        !this.checkOut.expiryYear ||
        !this.checkOut.expiryMonth
      )
        return true;
    },
  },
};
</script>

<style>
.home-page .plan-card__wrapper {
  box-shadow: 5px 5px 10px 3px #88888861;

}
.home-page .v-btn--is-elevated {
  box-shadow: 0px 3px 1px -2px rgb(243 159 60),
    0px 2px 2px 0px rgb(243 159 60 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.home-page .card-header__icon-wrapper{
  color:black
}
.home-page  .plan-card__description{
  color:black;
  text-align:center;
  padding:0 10px;
  margin:10px 0;font-weight: 600;

}
</style>
