<template>
    <div class="paymentSuccessImage">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-icon color="green" size="100">mdi-check-circle</v-icon>
            <h1 class="success-title">{{ watchLang.successMessage }}</h1>
            <p class="success-description">{{ watchLang.thankYouMessage }}</p>
            <v-btn
              large
              color="#F39F3C"
              :class="{ formButtonRTL: $vuetify.rtl == true }"
              style="text-transform: none; transition: 0.5s"
              @click="goToDashboard"
            >
              {{ watchLang.goToDashboard }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {};
    },
    methods: {
      async updatePaymentStatus() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        console.log(token);
        if (token) {
          try {
            // Fetch the plan ID and currency from the store
            const selectedPlan = urlParams.get("planid");
            const currency = urlParams.get("currency");
            const transId = urlParams.get("transactionId");;

            console.log(selectedPlan);
            console.log(currency);
            console.log(transId);
            // Patch the payment information using the token and plan
            await axios.patch(`/attempt/${token}/plan`, {
              plan_id: selectedPlan,
              payment_token: token,
              currency: currency,
              transaction_id: transId,
            });
  
            // Payment update was successful
            // this.$swal.fire({
            //   title: this.$vuetify.rtl ? "تم تحديث الدفع" : "Payment Updated",
            //   icon: "success",
            //   timer: 2000,
            //   showConfirmButton: false,
            // });
          } catch (err) {
            console.error("Error updating payment status:", err);
            this.$swal.fire({
              title: this.$vuetify.rtl ? "خطأ في الدفع" : "Payment Error",
              text: this.$vuetify.rtl
                ? "حدث خطأ في تحديث الدفع"
                : "An error occurred while updating the payment.",
              icon: "error",
            });
          }
        }
      },
      goToDashboard() {
        this.$router.push("/"); // Adjust this to your actual dashboard route
      },
    },
    computed: {
      watchLang() {
        if (this.$vuetify.rtl) {
          return {
            successMessage: "تم الدفع بنجاح!",
            thankYouMessage: "شكرًا لك! تم إتمام عملية الدفع الخاصة بك بنجاح.",
            goToDashboard: "العودة إلى الصفحة الرئيسية",
          };
        } else {
          return {
            successMessage: "Payment Successful!",
            thankYouMessage: "Thank you! Your payment has been successfully processed.",
            goToDashboard: "Go to Home",
          };
        }
      },
    },
    mounted() {
      this.updatePaymentStatus();
    },
  };
  </script>
  
  <style>
  .paymentSuccessImage {
    background: url("../assets/Connectme.png") 100% / cover;
    width: 100%;
    min-height: calc(100vh - 60px);
    padding-top: 170px;
    box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
  }
  .success-title {
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 20px;
  }
  .success-description {
    color: white;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .v-icon {
    margin-bottom: 20px;
  }
  .formButtonRTL {
    font-size: 1.2rem !important;
  }
  </style>
  