<template>
  <div >
    <div class="startCareerFormImage" >
      <v-container>
        <v-form v-model="isFormValid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                color="white"
                v-model="userData.userName"
                clearable
                :label="watchLang.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                color="white"
                v-model="userData.email"
                :rules="$vuetify.rtl ? emailRulesRTL : emailRules"
                clearable
                :label="watchLang.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!-- <v-card-text> -->
              <v-autocomplete
                v-model="userData.country"
                :items="getCountries"
                :loading="isLoading"
                @input="getCities"
                :search-input.sync="search"
                color="white"
                :item-text="localeText == 'en' ? 'name' : 'name_ar'"
                item-value="id"
                :label="watchLang.country"
                clearable
                placeholder="Start typing to Search"
                return-object
              ></v-autocomplete>
              <!-- </v-card-text> -->
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <v-autocomplete
                  v-model="userData.city"
                  :items="cityEntries"
                  :loading="cityIsLoading"
                  :disabled="!userData.country"
                  :search-input.sync="citySearch"
                  color="white"
                  clearable
                  :item-text="localeText == 'en' ? 'name' : 'name_ar'"
                  item-value="id"
                  :label="watchLang.city"
                  placeholder="Start typing to Search"
                  return-object
                ></v-autocomplete>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <v-autocomplete
                  v-model="userData.category"
                  :items="getCategories"
                  color="white"
                  :item-text="localeText == 'en' ? 'name' : 'name_ar'"
                  item-value="id"
                  clearable
                  :label="watchLang.category"
                  return-object
                ></v-autocomplete>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                small-chips
                color="white"
                accept=".pdf,.doc,.docx"
                :label="watchLang.cv"
                :rules="$vuetify.rtl ? rulesRTL : rules"
                clearable
                v-model="userData.cv"
              ></v-file-input>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-file-input
                small-chips
                accept=".pdf"
                color="white"
                :label="watchLang.coverLetter"
                :rules="$vuetify.rtl ? rulesRTL : rules"
                clearable
                prepend-icon=""
                v-model="userData.coverLetter"
              ></v-file-input>
            </v-col> -->
          </v-row>
        </v-form>
        <v-btn
          large
          color="#F39F3C"
          :class="{ formButtonRTL: $vuetify.rtl == true }"
          style="text-transform: none; transition: 0.5s"
          :disabled="formBtnDisabled"
          @click="sendAttemptData"
          >{{ watchLang.buttonText }}</v-btn
        >
      </v-container>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import axios from "axios";
export default {
  data: () => ({
    isFormValid: false,
    categoryEntries: [],
    countriesEntries: [],
    isLoading: false,
    search: "",
    //
    cityEntries: [],
    citySearch: null,
    cityIsLoading: false,
    //
    userData: {
      userName: "",
      email: "",
      country: null,
      city: null,
      category: "",
      cv: null,
      // coverLetter: null,
    },
    emailRules: [(v) => /.+@.+/.test(v) || "E-mail must be valid"],
    emailRulesRTL: [
      (v) => /.+@.+/.test(v) || "الايميل الالكتروني يجب أن يكون صحيح ",
    ],
    rules: [
      (value) =>
        !value || value.size < 5000000 || "File size should be less than 5 MB!",
      (value) =>
        !value ||
        value?.name.endsWith(".pdf") ||
        value?.name.endsWith(".docx") ||
        "File should be .pdf or .docx exstention",
    ],
    rulesRTL: [
      (value) =>
        !value ||
        value.size < 5000000 ||
        "يجب أن يكون حجم الملف أقل من 5 ميجا بايت",
      (value) =>
        !value ||
        value?.name.endsWith(".pdf") ||
        value?.name.endsWith(".docx") ||
        "يجب أن يكون الملف بصيغة pdf. أو .docx",
    ],
  }),
  methods: {
    async getCities() {
      try {
        this.cityIsLoading = true;
        if (!this.userData.country) {
          return;
        }
        if (!this.userData.city || !this.userData.country) {
          this.userData.city = null;
          this.cityIsLoading = false;
        }
        // if (this.userData.country.id == 161) {
        //   this.$store.state.currency = "AED";
        //   this.$store.state.paymentAuthorization =
        //     "pk_bfbcc642-36f4-42f9-8cb0-1c1cb6b297aa";
        // } else {
        //   this.$store.state.currency = "USD";
        //   this.$store.state.paymentAuthorization =
        //     "pk_5f4bd7cf-1f7e-44f7-ab85-70791147d824";
        // }

        this.$store.state.currency = "AED";
        const cities = await axios.get(`/country/${this.userData.country.id}`, {
          params: {
            size: 1000,
          },
        });
        console.log("cities.data.data.city",cities.data.data.city);
        this.cityEntries = cities.data.data.city;
      } catch (err) {
      } finally {
        this.cityIsLoading = false;
      }
    },
    sendAttemptData() {
      let modal_title = "";
      if (this.$vuetify.rtl) {
        modal_title = "يتم رفع معلوماتك الرجاء الإنتظار";
      } else {
        modal_title = "Uploading your information please be patient";
      }
      this.$swal.fire({
        position: "top",
        title: modal_title,
      });
      this.$swal.showLoading();
      this.$store.dispatch("firstStageDone", {
        name: this.userData.userName,
        email: this.userData.email,
        country: this.userData.country,
        city: this.userData.city,
        category: this.userData.category,
        cv: this.userData.cv,
        // cover_letter: this.userData.coverLetter,
      });
    },
  },
  computed: {
    getCategories() {
      this.categories = this.$store.getters.getCategories;

      this.categories.forEach((el) => {
        if (!el.name) {
          return;
        }
        this.categoryEntries.push(el);
      });
      return this.categoryEntries;
    },
    getCountries() {
      this.countriesEntries = this.$store.getters.getCountries;
      return this.countriesEntries;
    },
    localeText() {
      return i18n.locale;
    },
    formBtnDisabled() {
      if (
        !this.userData.userName ||
        !this.userData.email ||
        !this.isFormValid ||
        !this.userData.country ||
        !this.userData.city ||
        !this.userData.category ||
        !this.userData.cv
      )
        return true;
    },
    watchLang() {
      if (this.$vuetify.rtl) {
        let arTexts = {
          name: "الاسم",
          email: "الايميل",
          country: "البلد",
          city: "المدينة",
          category: "المسمى الوظيفي",
          cv: "ارفع سيرتك الذاتية",
          coverLetter: "ارفع الرسالة التعريفية",
          buttonText: "تسجيل",
        };
        return arTexts;
      } else {
        let enTexts = {
          name: "Name",
          email: "E-mail",
          country: "Country",
          city: "City",
          category: "Job title",
          cv: "Upload Your CV",
          coverLetter: "Upload Your Cover Letter",
          buttonText: "Register",
        };
        return enTexts;
      }
    },
  },
  mounted() {
    this.isLoading = true;
    let userData = this.$store.getters.getUserFormData;
    console.log("userData???", userData);
    this.userData.userName = userData.userName;
    this.userData.email = userData.email;
    this.userData.country = userData.country;
    this.userData.city = userData.city;
    this.userData.category = userData.category;
    this.userData.cv = userData.cv;
    this.getCities();
    this.isLoading = false;
  },
  // async created() {
  //   try {
  //     // const countries = await axios.get("/country", {
  //     //   params: {
  //     //     size: 1000,
  //     //   },
  //     // });
  //     // const categories = await axios.get("/category", {
  //     //   params: {
  //     //     size: 1000,
  //     //   },
  //     // });

  //     categories.data.categories.forEach((el) => {
  //       if (!el.name) {
  //         return;
  //       }
  //       this.categoryEntries.push(el);
  //     });
  //     this.countriesEntries = countries.data.countries;
  //   } catch (err) {
  //   } finally {
  //   }
  // },
};
</script>

<style>
.startCareerFormImage {
  background: url("../assets/pexels-elvis-2528118.jpg") 100% / cover;
  width: 100%;
  min-height: calc(100vh - 60px);
   padding-top: 170px;
  box-shadow: inset 0 0 0 2000px rgba(33, 123, 138, 0.75);
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0 !important;
}
.theme--dark.v-sheet {
  background-color: white !important;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  color: black !important;
}
.theme--dark.v-list-item .v-list-item__mask {
  background: gray !important;
}
.v-autocomplete__content.v-menu__content {
  z-index: 999 !important;
}
.formButtonRTL {
  font-size: 1.2rem !important;
}
.v-messages__message {
  font-size: 0.95rem;
  color: white;
  line-height: unset;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white !important;
}
</style>